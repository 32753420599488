import { graphql } from 'gatsby'
import React from 'react'
import SearchSection from '../components/Sections/SearchSection/SearchSection';

import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'

const PageSearch = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom: {
            seoTitle,
            seoDescription,
            slug
        },
        allContentfulPageCustom: {
            nodes
        },
        allContentfulPageRecipe,
        allContentfulPageProduct
    }
}) => {
    const lang = useUrl()
    return (
        <div>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
                lang={lang}
                node_locale={pageContext.node_locale}
                hrefLangs={nodes}
            />
            <SearchSection
                productArray={allContentfulPageProduct.nodes}
                recipeArray={allContentfulPageRecipe.nodes}
            />
        </div>
    )
}

export const PagePageContactQuery = graphql`
    query pagePageContact($id:String!, $contentful_id: String!, $node_locale: String! ){
        contentfulPageCustom(id:{eq:$id}){
            seoTitle
            seoDescription{
                seoDescription
            }
            slug
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id},slug: { ne: null }}){
            nodes {
                slug
                node_locale
            }
        }
        allContentfulPageRecipe(filter: {node_locale: {eq: $node_locale}, slug: {ne: null}}) {
            nodes {
                slug
                seoTitle
                seoDescription {
                    seoDescription
                }
                node_locale
                thumbnailImage {
                    gatsbyImageData
                }
                thumbnailTitle
            }
        }
        allContentfulPageProduct(filter: {node_locale: {eq: $node_locale}, slug: {ne: null}})  {
            nodes {
                slug
                thumbnailTitle
                seoTitle
                seoDescription {
                    seoDescription
                }
                node_locale
                thumbnailImage {
                    gatsbyImageData
                }
                title
            }
        }
    }
`

export default PageSearch