import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'gatsby'
import * as styles from './search-section.module.scss'
import { BiSearch } from 'react-icons/bi'
import { BiX } from 'react-icons/bi'
import { useDictionaryQuery } from '../../../fragments/hooks/useDictionaryQuery'
import { useLocalPath } from '../../hooks/useLocalPath'
const SearchSection = ({
    productArray,
    recipeArray
}) => {
    const [searchText, setSearchText] = useState("")
    const [filteredProducts, setFilteredProducts] = useState([])
    const [filteredRecipes, setFilteredRecipes] = useState([])

    const [type, setType] = useState("all")
    const{all, recipes, products, viewMoreProducts ,viewMoreRecipes, noProductsFound,noRecipesFound, pleaseEnterHere} = useDictionaryQuery()
    useEffect(() => {
        if (searchText) {
            const array = productArray.filter(({
                seoTitle,
                seoDescription,
                thumbnailTitle,
                title
            }, index) =>
                title?.toLocaleLowerCase().includes(searchText)
                || seoTitle.toLocaleLowerCase().includes(searchText)
                || thumbnailTitle?.toLocaleLowerCase().includes(searchText)
                || seoDescription?.seoDescription.toLocaleLowerCase().includes(searchText)
            )
            setFilteredProducts(array)

            const rArray = recipeArray.filter(({
                seoTitle,
                seoDescription,
                thumbnailTitle
            }, index) =>
                seoTitle.toLocaleLowerCase().includes(searchText)
                || thumbnailTitle?.toLocaleLowerCase().includes(searchText)
                || seoDescription?.seoDescription.toLocaleLowerCase().includes(searchText)
            )
            setFilteredRecipes(rArray)
        } else {
            setFilteredProducts([])
            setFilteredRecipes([])
        }
    }, [searchText])

    return (
        <div className={`container container-lg`}>
            <div className={styles.searchContainer}>
                <label>
                <BiSearch className={styles.searchIcon} />
                <input
                    type="text"
                    placeholder={pleaseEnterHere}
                    onChange={e => setSearchText(e.target.value.toLocaleLowerCase())}
                    className={styles.searchField}
                    value={searchText}
                />
                <BiX className={styles.closeIcon} onClick={e => setSearchText("")} />
                </label>
            </div>
            <div className={`${styles.types}`}>
                <p className={`${styles.typeItem} ${type === "all" ? styles.activeTypeItem : ''}`} onClick={e => setType("all")} >{all}</p>
                <p className={`${styles.typeItem} ${type === "products" ? styles.activeTypeItem : ''}`} onClick={e => setType("products")}>{products}</p>
                <p className={`${styles.typeItem} ${type === "recipes" ? styles.activeTypeItem : ''}`} onClick={e => setType("recipes")}>{recipes}</p>
            </div>
            <div className={styles.resultSection}>
                {
                    type === "products"
                        ? <div className={`${styles.resultContainer}`}>
                            {filteredProducts.length > 0
                                ? filteredProducts.map(({ thumbnailImage, thumbnailTitle, slug }, index) => {
                                    return (
                                        <ProductItem
                                            thumbnailImage={thumbnailImage}
                                            thumbnailTitle={thumbnailTitle}
                                            slug={slug}
                                            key={index}
                                        />
                                    )
                                })
                                : <div className={styles.notFound}>
                                    {noProductsFound}
                                </div>
                            }
                        </div>
                        : type === "recipes"
                            ? <div className={`${styles.resultContainer}`}>
                                {filteredRecipes.length > 0
                                    ? filteredRecipes.map(({ thumbnailImage, thumbnailTitle, slug }, index) => {
                                        return (
                                            <RecipeItem
                                                thumbnailImage={thumbnailImage}
                                                thumbnailTitle={thumbnailTitle}
                                                slug={slug}
                                                key={index}
                                            />
                                        )
                                    })
                                    : <div className={styles.notFound}>
                                        {noRecipesFound}
                                    </div>
                                }
                            </div>
                            : <>
                                <div className={`${styles.resultContainer}`}>
                                    {
                                        filteredProducts.length > 5
                                            ? <div className={styles.viewMoreButton} onClick={e => setType("products")}>
                                                <span>{viewMoreProducts}</span>
                                            </div>
                                            : <h3 className={`${styles.sectionTitle}`}>{products}</h3>
                                    }
                                    {filteredProducts.length > 0
                                        ? filteredProducts.map(({ thumbnailImage, thumbnailTitle, slug }, index) => {
                                            return index < 5 ? (
                                                <ProductItem
                                                    thumbnailImage={thumbnailImage}
                                                    thumbnailTitle={thumbnailTitle}
                                                    slug={slug}
                                                    key={index}
                                                />
                                            ) : null
                                        })
                                        : <div className={styles.notFound}>
                                            {noProductsFound}
                                        </div>
                                    }
                                </div>
                                <div className={`${styles.resultContainer}`}>
                                    {
                                        filteredRecipes.length > 5
                                            ? <div className={styles.viewMoreButton} onClick={e => setType("recipes")}>
                                                <span>{viewMoreRecipes}</span>
                                            </div>
                                            : <h3 className={`${styles.sectionTitle}`}>{recipes}</h3>
                                    }
                                    {filteredRecipes.length > 0
                                        ? filteredRecipes.map(({ thumbnailImage, thumbnailTitle, slug }, index) => {
                                            return index < 5 ? (
                                                <RecipeItem
                                                    thumbnailImage={thumbnailImage}
                                                    thumbnailTitle={thumbnailTitle}
                                                    slug={slug}
                                                    key={index}
                                                />
                                            ) : null
                                        })
                                        : <div className={styles.notFound}>
                                            {noRecipesFound}
                                        </div>
                                    }
                                </div>
                            </>
                }

            </div>
        </div>
    )
}

const ProductItem = ({ thumbnailImage, thumbnailTitle, slug }) => {
    return (
        <div className={`${styles.resultItemContainer}`}>
            <Link className={`${styles.resultItemImageLink}`} to={useLocalPath(slug)}>
                <GatsbyImage className={`${styles.resultItemImage}`} image={thumbnailImage.gatsbyImageData} objectFit="contain" alt={`${thumbnailTitle}_img`} />
            </Link>
            <Link to={useLocalPath(slug)} className={`${styles.resultItemContentLink}`}>
                <h4 className={styles.cardText}>{thumbnailTitle}</h4>
            </Link>
        </div>
    )
}

const RecipeItem = ({ thumbnailImage, thumbnailTitle, slug }) => {
    return (
        <div className={`${styles.resultItemContainer}`}>
            <Link className={`${styles.resultItemImageLink}`} to={useLocalPath(slug)}>
                <GatsbyImage className={`${styles.resultItemImage}`} image={thumbnailImage.gatsbyImageData} objectFit="contain" alt={`${thumbnailTitle}_img`} />
            </Link>
            <Link to={useLocalPath(slug)} className={`${styles.resultItemContentLink}`}>
                <h4 className={styles.cardText}>{thumbnailTitle}</h4>
            </Link>
        </div>
    )
}

export default SearchSection