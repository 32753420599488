// extracted by mini-css-extract-plugin
export var activeTypeItem = "search-section-module--active-type-item--68784";
export var button = "search-section-module--button--56898";
export var cafeFormIntro = "search-section-module--cafe-form-intro--55122";
export var cardText = "search-section-module--card-text--01f05";
export var closeIcon = "search-section-module--close-icon--203e5";
export var highlight = "search-section-module--highlight--dd42b";
export var notFound = "search-section-module--not-found--d7ab0";
export var redButton = "search-section-module--red-button--3364a";
export var redTransparentButton = "search-section-module--red-transparent-button--b4a2f";
export var resultContainer = "search-section-module--result-container--5c894";
export var resultItemContainer = "search-section-module--result-item-container--6ae4e";
export var resultItemContentLink = "search-section-module--result-item-content-link--eef23";
export var resultItemImage = "search-section-module--result-item-image--7b94c";
export var resultItemImageLink = "search-section-module--result-item-image-link--f3d32";
export var resultSection = "search-section-module--result-section--8902d";
export var searchContainer = "search-section-module--search-container--899dc";
export var searchField = "search-section-module--search-field--5812d";
export var searchIcon = "search-section-module--search-icon--2fe64";
export var sectionTitle = "search-section-module--section-title--4e2d6";
export var transparentButton = "search-section-module--transparent-button--b63c6";
export var typeItem = "search-section-module--type-item--c4f09";
export var types = "search-section-module--types--aa58c";
export var viewMoreButton = "search-section-module--view-more-button--cd225";